import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, Grid,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import AreaFilter from './AreaFilter'

import Filter from './Filter'
import localizedStrings from './localizedStrings'

const Filters = (props) => {
  const { useFindComparablesPayload } = props
  const {
    activeFiltersCount,
    browseComparableType,
    callbacks: {
      changeFilter,
      resetFilters,
      setModalState,
    },
    filtersWithoutArea,
    pricefinderAreaFilter,
    showFilters,
  } = useFindComparablesPayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  if (!showFilters){
    return (
      <Button
        buttonStyle="ghostEdit"
        icon={activeFiltersCount ? null : <FontAwesomeIcon icon={faPlus} />}
        marginTop="large"
        onClick={() => setModalState({ showFilters: true })}
        size="medium"
      >
        {activeFiltersCount
          ? formatString(strings.manageFilters, { activeFiltersCount }) || `Manage (${activeFiltersCount} Filters)`
          : strings.addFilters || 'Add Filters'}
      </Button>
    )
  }

  return (
    <Box flexDirection="column" flexShrink={0} marginTop="large">
      <FormField label={strings.labels?.marketCategory || 'Market Category'}>
        <select
          onChange={e => setModalState({ browseComparableType: e.target.value })}
          value={browseComparableType}
        >
          <option value="">{strings.options?.pleaseSelect || 'Please Select...'}</option>
          <option value="market_all">{strings.options?.marketAll || 'All in the market'}</option>
          <option value="organization_all">{strings.options?.organizationAll || 'All in your organization'}</option>
          <option value="market_current">{strings.options?.marketCurrent || 'Listed in the market'}</option>
          <option value="organization_current">{strings.options?.organizationCurrent || 'Listed by your organization'}</option>
          <option value="market_sold">{strings.options?.marketSold || 'Sold in the market'}</option>
          <option value="organization_sold">{strings.options?.organizationSold || 'Sold by your organization'}</option>
          <option value="market_rental">{strings.options?.marketRental || 'Rental Comparables in the market'}</option>
        </select>
      </FormField>

      <Grid
        gridGap="medium"
        gridTemplateColumns="repeat(2, 1fr)"
        marginTop="medium"
        width={['auto', '100%']}
      >
        {filtersWithoutArea.map(filter => (
          <Filter changeFilter={changeFilter} filter={filter} key={filter.id} />
        ))}
      </Grid>

      {pricefinderAreaFilter && (
        <AreaFilter useFindComparablesPayload={useFindComparablesPayload} strings={strings} />
      )}

      <Button
        buttonStyle="secondaryUtility"
        marginTop="large"
        onClick={resetFilters}
        size="medium"
      >
        {strings.clear || 'Clear all filters'}
      </Button>

      <Button
        buttonStyle="primaryEdit"
        marginTop="medium"
        onClick={() => setModalState({ showFilters: false })}
        size="medium"
      >
        {strings.save || 'Save Filters'}
      </Button>
    </Box>
  )
}

Filters.propTypes = {
  useFindComparablesPayload: PropTypes.object.isRequired,
}

export default Filters
