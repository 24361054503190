import { Box, FormField } from '@campaignhub/suit-theme'

import type { UseFindComparablesPayload } from '../../../hooks/useFindComparables'

type Strings = {
  areaLabel?: string,
}

interface AreaFilterProps {
  strings: Strings,
  useFindComparablesPayload: UseFindComparablesPayload,
}

const AreaFilter = (props: AreaFilterProps) => {
  const { strings, useFindComparablesPayload } = props
  const {
    areaFilter,
    areaFilterDisplayValue,
    callbacks: {
      handleAreaFilterChange,
      handleAreaUnitChange,
    },
    priceFinderAreaOptions,
    selectedAreaUnitValue,
  } = useFindComparablesPayload

  return (
    <Box marginTop="large" justifyContent="space-between" alignItems="end">
      <FormField label={strings.areaLabel || 'Area Unit'} boxProps={{ width: '45%' }}>
        <select onChange={handleAreaUnitChange} value={selectedAreaUnitValue}>
          {priceFinderAreaOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      </FormField>

      <FormField
        label={`Area (${selectedAreaUnitValue})`}
        boxProps={{ width: '25%' }}
      >
        <input
          onChange={
            e => handleAreaFilterChange({
              filterId: areaFilter.id, key: 'value', value: e.target.value,
            })
          }
          placeholder="min"
          type="number"
          value={areaFilterDisplayValue.value}
        />
      </FormField>

      <FormField boxProps={{ width: '25%' }}>
        <input
          onChange={
            e => handleAreaFilterChange({
              filterId: areaFilter.id, key: 'valueMax', value: e.target.value,
            })
          }
          placeholder="max"
          type="number"
          value={areaFilterDisplayValue.valueMax}
        />
      </FormField>
    </Box>
  )
}

export default AreaFilter
