import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, modifyGroupedIdsSort, multiFilteredObjectArraySelector, toggleArray,
} from '@campaignhub/javascript-utils'

import { snakeCaseToCamelCase } from '@functions/string'

import type { MixPanelType } from '@hooks/useMixpanel'
import useDispatch from '@hooks/useDispatch'
import useIntercom, { TrackEventParams } from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useProjects from '@hooks/useProjects'
import useSelector from '@hooks/useSelector'

import * as projectActions from '@redux/modules/project'

import defaultFormState, { requiredFields } from '@models/project'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type {
  AddressModel,
  ContactModel,
  CustomFieldModel, ProjectModel, ProjectRequestOptions, TeamModel, UserModel,
} from '@models/types'

const watchEntityKeys = ['images']

type SetEntityState = (params: Record<string, number[]>) => void

export const generateUrls = (project?: Partial<ProjectModel>) => {
  const { id } = project || {}

  return {
    analyticsUrl: `#/projects/${id}/analytics`,
    comparablesUrl: `#/projects/${id}/comparables`,
    documentsUrl: `#/projects/${id}/documents`,
    editUrl: `#/projects/${id}/edit`,
    eventsUrl: `#/projects/${id}/events`,
    mediaUrl: `#/projects/${id}/media`,
    overviewArchived: '#/overviews/projects?status=archived',
    overviewCurrent: '#/overviews/projects?status=current',
    overviewUrl: `#/projects/${id}`,
    quotesUrl: `#/projects/${id}/quotes`,
  }
}

type CreateProjectParams = {
  customFields: CustomFieldModel[],
  dispatch: AppDispatch,
  dispatchMixpanelEvent: MixPanelType,
  hasFilteredProjects?: boolean,
  projectParams: Partial<ProjectModel>,
  requestOptions?: ProjectRequestOptions,
  trackEvent: (params: TrackEventParams) => void,
}

const createProject = (params: CreateProjectParams) => {
  const {
    customFields, dispatch, dispatchMixpanelEvent, hasFilteredProjects, projectParams, requestOptions, trackEvent,
  } = params
  const { createProject: createFn } = projectActions

  return dispatch(createFn(projectParams, customFields, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      dispatchMixpanelEvent('Project Created Manually')
      if (!hasFilteredProjects) trackEvent({ eventName: 'ProjectCreated', eventPayload: { created: true } })

      return {
        success,
        data,
        redirectUrl: `#/projects/${id}`,
      }
    }

    return response
  })
}

type UpdateProjectParams = {
  customFields: CustomFieldModel[],
  dispatch: AppDispatch,
  project: ProjectModel,
  projectParams: Partial<ProjectModel>,
  requestOptions?: ProjectRequestOptions,
}

const updateProject = (params: UpdateProjectParams) => {
  const {
    customFields, dispatch, project, projectParams, requestOptions,
  } = params
  const { data } = projectParams

  const { updateProject: updateFn } = projectActions

  const updatedParams = {
    id: project.id,
    ...projectParams,
  }

  if (data){
    const projectData = project.data || {}

    updatedParams.data = JSON.stringify({
      ...cloneDeep(projectData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type DeleteProjectParams = {
  dispatch: AppDispatch,
  project: DeleteParams<ProjectModel>,
}

const deleteProject = (params: DeleteProjectParams) => {
  const { dispatch, project } = params
  const { deleteProject: deleteFn } = projectActions

  return dispatch(deleteFn(project))
}

type ImportProjectParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: MixPanelType,
  externalId: number,
  hasFilteredProjects?: boolean,
  integrationPlatformKey: string,
  organizationId: number,
  requestOptions?: ProjectRequestOptions,
  trackEvent: (params: TrackEventParams) => void,
}

export const importProject = async (params: ImportProjectParams) => {
  const {
    dispatch,
    dispatchMixpanelEvent,
    externalId,
    hasFilteredProjects,
    integrationPlatformKey,
    organizationId,
    requestOptions,
    trackEvent,
  } = params
  const { importProject: importFn } = projectActions

  const response = await dispatch(importFn(externalId, integrationPlatformKey, organizationId, requestOptions))
  const { success, data } = response
  if (success){
    const { entity: { id } } = data

    dispatchMixpanelEvent('Project Created via Import', {
      external_platform: snakeCaseToCamelCase(integrationPlatformKey),
    })
    if (!hasFilteredProjects) trackEvent({ eventName: 'ProjectCreated', eventPayload: { created: true } })

    return {
      success,
      data,
      redirectUrl: `#/projects/${id}`,
    }
  }
  return response
}

type AddProjectUserOrTeamParams = {
  selected: {
    id: number,
    key: string,
  },
  userGroupKey: string,
  entityState: ProjectModel,
  setEntityState: SetEntityState,
}

const addProjectUserOrTeam = (params: AddProjectUserOrTeamParams) => {
  const {
    selected, userGroupKey, entityState, setEntityState,
  } = params
  const { team_ids } = entityState
  const { key } = selected

  // userTypeKey = 'lead_user_ids'
  const userType: number[] = entityState[userGroupKey]

  const [entityType] = key.split('-')

  if (entityType === 'User'){
    const updatedUsers = toggleArray(userType, selected.id)
    setEntityState({ [userGroupKey]: updatedUsers })
  }

  if (entityType === 'Team'){
    const updatedTeams = toggleArray(team_ids, selected.id)
    setEntityState({ team_ids: updatedTeams })
  }
}

type DeleteProjectUserParams = {
  userId: number,
  userTypeKey: string,
  entityState: ProjectModel,
  setEntityState: SetEntityState,
}

const deleteProjectUser = (params: DeleteProjectUserParams) => {
  const {
    userId, userTypeKey, entityState, setEntityState,
  } = params
  const userType = entityState[userTypeKey]

  const updatedUsers = toggleArray(userType, userId)

  setEntityState({ [userTypeKey]: updatedUsers })
}

type DeleteProjectTeamParams = {
  entityState: ProjectModel,
  setEntityState: SetEntityState,
  teamId: number,
}

const deleteProjectTeam = (params: DeleteProjectTeamParams) => {
  const { teamId, entityState, setEntityState } = params
  const { team_ids } = entityState

  const updatedTeams = toggleArray(team_ids, teamId)

  setEntityState({ team_ids: updatedTeams })
}

type ModifyTeamsSortParams = {
  fromIndex: number,
  payload: {},
  setEntityState: SetEntityState,
  teamIds: { team_ids: number[] },
  toIndex: number,
}

const modifyTeamsSort = (params: ModifyTeamsSortParams) => {
  const {
    fromIndex, toIndex, payload, teamIds, setEntityState,
  } = params
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, teamIds)

  setEntityState({ team_ids: groupedIds.team_ids })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProjectForm(
  project: Partial<ProjectModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const projectForm = useForm(
    defaultFormState,
    { entity: project, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [project.id, project.cache_key],
  )

  return {
    ...projectForm,
  }
}

export const useRelations = (initProject: Partial<ProjectModel> = {}) => {
  const { entity: project }: {entity: ProjectModel} = useLatestEntity(initProject, 'projects')
  const { address_id, organization_id, project_type_id } = project

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    addresses, organizations, organizationTypes, projectTypes,
  } = entities

  const address = address_id ? addresses[address_id] || {} : {} as AddressModel
  const organization = organizations[organization_id] || {}
  const organizationType = organizationTypes[organization.organization_type_id] || {}
  const projectType = projectTypes[project_type_id] || {}

  return {
    address,
    organization,
    organizationType,
    project,
    projectType,
  }
}

function useProject(initEntity: Partial<ProjectModel> = {}) {
  const { entity: project }: { entity: ProjectModel} = useLatestEntity(initEntity, 'projects')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    contacts, projectTypes, targetAudiences, teams, users,
  } = entities

  const { creating, loading, updating } = useSelector(reduxState => reduxState.projects)

  const {
    address, organization, organizationType, projectType,
  } = useRelations(project)

  const defaultProjectType = Object.values(projectTypes).find(
    type => type.default_for_organization_type_id === organizationType.id,
  )

  const contactIds = digObject(project, 'contact_ids', [])
  const leadUserIds = digObject(project, 'lead_user_ids', [])
  const supportUserIds = digObject(project, 'support_user_ids', [])
  const teamIds = digObject(project, 'team_ids', [])

  const leadUsers = leadUserIds.map((userId: number) => users[userId]).filter((user: UserModel) => user)
  const filteredTeams = teamIds.map((teamId: number) => teams[teamId] || {})
    .filter((team: TeamModel) => teamIds.includes(team.id))
  const supportUsers = supportUserIds.map((userId: number) => users[userId]).filter((user: UserModel) => user)
  const filteredContacts = contactIds.map((contactId: number) => contacts[contactId])
    .filter((contact: ContactModel) => contact)

  const targetAudienceIds: number[] = digObject(project, 'target_audience_ids', [])
  const filteredTargetAudiences = Object.values(targetAudiences)
    .filter(targetAudience => targetAudienceIds.includes(targetAudience.id))

  const imageFilters = [
    { key: 'subject_type', value: 'Project' },
    { key: 'subject_id', value: project.id },
  ]

  const filteredProjectImages = useMemo(
    () => multiFilteredObjectArraySelector({ entities }, 'images', imageFilters),
    [imagesUpdatedAt, project.id],
  )

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const { hasFilteredProjects } = useProjects({
    filters: {},
  })

  const { callbacks: { trackEvent } } = useIntercom()

  return {
    address,
    callbacks: {
      addProjectUserOrTeam: (
        selected: {
          id: number,
          key: string,
        },
        userTypeKey: string,
        entityState: ProjectModel,
        setEntityState: SetEntityState,
      ) => addProjectUserOrTeam({
        selected, userGroupKey: userTypeKey, entityState, setEntityState,
      }),
      createProject: (
        projectParams: Partial<ProjectModel>,
        customFields: CustomFieldModel[],
        entityOptions?: ProjectRequestOptions,
      ) => (
        createProject({
          customFields,
          dispatch,
          dispatchMixpanelEvent,
          hasFilteredProjects,
          projectParams,
          requestOptions: entityOptions,
          trackEvent,
        })
      ),
      deleteProjectTeam: (
        teamId: number,
        entityState: ProjectModel,
        setEntityState: SetEntityState,
      ) => deleteProjectTeam({ entityState, setEntityState, teamId }),
      deleteProjectUser: (
        userId: number,
        userTypeKey: string,
        entityState: ProjectModel,
        setEntityState: SetEntityState,
      ) => deleteProjectUser({
        entityState, setEntityState, userId, userTypeKey,
      }),
      createOrEditProject: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditProjectModal',
        payload: { project },
      }),
      deleteProject: () => deleteProject({ dispatch, project }),
      importProject: (
        externalId: number,
        integrationPlatformKey: string,
        organizationId: number,
        requestOptions: ProjectRequestOptions,
      ) => importProject({
        dispatch,
        dispatchMixpanelEvent,
        externalId,
        hasFilteredProjects,
        integrationPlatformKey,
        organizationId,
        requestOptions,
        trackEvent,
      }),
      manageProjectAttachments: () => launchModal({
        callbacks,
        modalKey: 'ManageProjectAttachmentsModal',
        payload: {
          callbacks: {
            updateProject: (
              projectParams: Partial<ProjectModel>,
              _: CustomFieldModel[],
              entityOptions?: ProjectRequestOptions,
            ) => (
              updateProject({
                customFields: [], dispatch, project, projectParams, requestOptions: entityOptions,
              })
            ),
          },
          project,
        },
      }),
      manageProjectContacts: () => launchModal({
        callbacks,
        modalKey: 'ManageProjectContactsModal',
        payload: {
          callbacks: {
            updateProject: (
              projectParams: Partial<ProjectModel>,
              _: CustomFieldModel[],
              entityOptions?: ProjectRequestOptions,
            ) => (
              updateProject({
                customFields: [], dispatch, project, projectParams, requestOptions: entityOptions,
              })
            ),
          },
          project,
        },
      }),
      manageProjectExternalPlatforms: (platformType: string) => launchModal({
        callbacks,
        modalKey: 'ManageProjectExternalPlatformsModal',
        payload: {
          callbacks: {
            updateProject: (
              projectParams: Partial<ProjectModel>,
              _: CustomFieldModel[],
              entityOptions?: ProjectRequestOptions,
            ) => (
              updateProject({
                customFields: [], dispatch, project, projectParams, requestOptions: entityOptions,
              })
            ),
          },
          platformType,
          project,
        },
      }),
      manageProjectTeams: () => launchModal({
        callbacks,
        modalKey: 'ManageProjectTeamsModal',
        payload: {
          callbacks: {
            updateProject: (
              projectParams: Partial<ProjectModel>,
              _: CustomFieldModel[],
              entityOptions?: ProjectRequestOptions,
            ) => (
              updateProject({
                customFields: [], dispatch, project, projectParams, requestOptions: entityOptions,
              })
            ),
          },
          project,
        },
      }),
      manageProjectUsers: () => launchModal({
        callbacks,
        modalKey: 'ManageProjectUsersModal',
        payload: {
          callbacks: {
            updateProject: (
              projectParams: Partial<ProjectModel>,
              _: CustomFieldModel[],
              entityOptions?: ProjectRequestOptions,
            ) => (
              updateProject({
                customFields: [], dispatch, project, projectParams, requestOptions: entityOptions,
              })
            ),
          },
          project,
        },
      }),
      modifyTeamsSort: (
        fromIndex: number,
        toIndex: number,
        payload: {},
        projectTeamIds: { team_ids: number[] },
        setEntityState: SetEntityState,
      ) => modifyTeamsSort({
        fromIndex, toIndex, payload, teamIds: projectTeamIds, setEntityState,
      }),
      updateProject: (
        projectParams: Partial<ProjectModel>,
        customFields: CustomFieldModel[],
        entityOptions?: ProjectRequestOptions,
      ) => (
        updateProject({
          customFields, dispatch, project, projectParams, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    defaultProjectType: defaultProjectType || {},
    filteredContacts,
    filteredProjectImages,
    filteredTargetAudiences,
    filteredTeams,
    leadUsers,
    loading: loading || !project.type,
    organization,
    project,
    projectType,
    supportUsers,
    updating,
    urls: generateUrls(project),
  }
}

export default useProject
