const AREA_CONVERSION_FACTORS = {
  m2: 1,
  ac: 4046.86,
  ha: 10000,
}

export type AreaUnit = keyof typeof AREA_CONVERSION_FACTORS;

interface ConvertAreaParams {
  value: string,
  fromUnit: AreaUnit,
  toUnit: AreaUnit,
}

const convertArea = (params: ConvertAreaParams) => {
  const { value, fromUnit, toUnit } = params
  const parsedValue = parseFloat(value)

  if (Number.isNaN(parsedValue)){
    return ''
  }

  const fromFactor = AREA_CONVERSION_FACTORS[fromUnit]
  const toFactor = AREA_CONVERSION_FACTORS[toUnit]

  if (!fromFactor || !toFactor){
    return ''
  }

  const valueInSqM = parsedValue * fromFactor
  const convertedValue = valueInSqM / toFactor

  return convertedValue.toFixed(0)
}

export default convertArea
