import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  Button, SidebarModal,
} from '@campaignhub/suit-theme'

import {
  generateApplicationUrl,
  getQueryParamsFromHash,
  parsePermittedQueryParams,
} from '@campaignhub/javascript-utils'

import useComparables from '@hooks/useComparables'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'

import ButtonWithOauth from '@components/ButtonWithOauth'

import generateRedirectUrl from '@functions/generateRedirectUrl'
import comparablesTooltipContent from '@functions/comparables'

import SidebarCustomHeaderWithTooltip from '@components/SidebarCustomHeaderWithTooltip'
import BrowseComparables from './components/BrowseComparables'
import CloneComparables from './components/CloneComparables'
import ComparableSourceSelector from './components/ComparableSourceSelector'
import SearchComparables from './components/SearchComparables'

import useFindComparables from './hooks/useFindComparables'

import localizedStrings from './localizedStrings'

const getSubmitButtonText = (action, strings) => {
  if (action === 'browse') return strings.submitButton?.browse || 'Browse'
  if (action === 'search') return strings.submitButton?.search || 'Search'
  return strings.submitButton?.default || 'Make a Selection'
}

const getModalHeaderText = (action, strings) => {
  if (action === 'browse') return strings.title?.browse || 'Browse'
  if (action === 'search') return strings.title?.search || 'Search'
  if (action === 'create') return strings.title?.create || 'Create'
  if (action === 'clone') return strings.title?.clone || 'Clone'
  return 'Find'
}

const handleSubmit = (params) => {
  const {
    action, dispatchMixpanelEvent, selectedIntegrationPlatformKey, submitAction,
  } = params

  if (action === 'browse'){
    dispatchMixpanelEvent('Comparables Browse', { integration_platform: selectedIntegrationPlatformKey })
  }

  submitAction().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
    }
  })
}

const FindComparablesModal = (props) => {
  const {
    callbacks, modalKey, projectId, showModal,
  } = props
  const { closeModal } = callbacks

  const useFindComparablesPayload = useFindComparables(projectId)
  const {
    action,
    browseComplete,
    callbacks: {
      setModalState,
      submitAction,
    },
    isMatchedWithDataProvider,
    searchComplete,
    searchString,
    searching,
    selectedIntegrationPlatformKey,
    useAuthorizeIntegrationPayload,
  } = useFindComparablesPayload

  const complete = browseComplete || searchComplete

  const useComparablesPayload = useComparables({
    filters: {
      projectId,
    },
  })
  const { filteredComparables } = useComparablesPayload

  const {
    integrationExpired,
    integration,
    oauthProviderKey,
  } = useAuthorizeIntegrationPayload || {}

  const {
    owner_id, owner_type, subject_id, subject_type,
  } = integration || {}

  const { applicationUrlWithRedirect: redirectUrl } = generateApplicationUrl({
    applicationKey: 'engage',
    environment: process.env.REACT_APP_APPLICATION_ENV,
    hash: `/#/projects/${projectId}/comparables/`,
    redirectUrl: `${generateRedirectUrl()}&showModal=FindComparablesModal&action=${action}&selectedIntegrationPlatformKey=${selectedIntegrationPlatformKey}`,
  })

  const {
    action: urlAction,
    selectedIntegrationPlatformKey: urlSelectedIntegrationPlatformKey,
  } = parsePermittedQueryParams(
    getQueryParamsFromHash(),
    ['action', 'selectedIntegrationPlatformKey'],
  )

  useEffect(() => {
    if (urlAction){ setModalState({ action: urlAction }) }

    if (urlSelectedIntegrationPlatformKey){
      setModalState({ selectedIntegrationPlatformKey: urlSelectedIntegrationPlatformKey })
    }
  }, [urlAction, urlSelectedIntegrationPlatformKey])

  const { strings } = useLocalization(localizedStrings)

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarCustomHeaderWithTooltip
        callbacks={callbacks}
        title={getModalHeaderText(action, strings)}
        titleSecondLine={strings.title?.comparables || 'Comparables'}
        tooltipContent={comparablesTooltipContent}
      />

      <SidebarModal.Content>
        {action === 'default' && (
          <ComparableSourceSelector callbacks={{ setModalState }} projectId={projectId} />
        )}

        {action === 'browse' && (
          <BrowseComparables
            projectId={projectId}
            useFindComparablesPayload={useFindComparablesPayload}
          />
        )}

        {action === 'clone' && (
          <CloneComparables
            projectId={projectId}
            useFindComparablesPayload={useFindComparablesPayload}
          />
        )}

        {action === 'search' && (
          <SearchComparables
            filteredComparables={filteredComparables}
            projectId={projectId}
            useFindComparablesPayload={useFindComparablesPayload}
          />
        )}
      </SidebarModal.Content>

      {(action !== 'clone' && action !== 'default'
      && (isMatchedWithDataProvider || (!isMatchedWithDataProvider && action === 'search') || urlAction))
      && (
        <SidebarModal.Footer>
          {(action === 'browse' || action === 'search') && integrationExpired && (
            <ButtonWithOauth
              authProviderKey={oauthProviderKey}
              buttonStyle="primaryCreate"
              disabled={false}
              queryParams={{
                integration_platform_key: selectedIntegrationPlatformKey,
                owner_id,
                owner_type,
                redirect: redirectUrl,
                subject_id,
                subject_type,
              }}
              size="large"
            >
              {strings.authorizeIntegrationButton || 'Authorize Integration'}
            </ButtonWithOauth>
          )}

          {!complete && !integrationExpired && (
            <Button
              buttonStyle="primaryEdit"
              disabled={action === 'search' && (!searchString || !selectedIntegrationPlatformKey)}
              loading={searching}
              onClick={() => handleSubmit({
                action, dispatchMixpanelEvent, selectedIntegrationPlatformKey, submitAction,
              })}
              size="large"
            >
              {getSubmitButtonText(action, strings)}
            </Button>
          )}

          {complete && (
            <>
              <Button
                buttonStyle="secondary"
                onClick={() => setModalState({ action: 'default' })}
                size="medium"
                style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
              >
                {strings.submitButton?.back || 'Go Back'}
              </Button>

              <Button
                buttonStyle="primaryCreate"
                onClick={() => closeModal()}
                size="medium"
                style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
              >
                {strings.submitButton?.finish || 'Finish'}
              </Button>
            </>
          )}
        </SidebarModal.Footer>
      )}
    </SidebarModal>
  )
}

FindComparablesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  projectId: PropTypes.number,
  showModal: PropTypes.bool,
}

FindComparablesModal.defaultProps = {
  modalKey: 'FindComparablesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <FindComparablesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
