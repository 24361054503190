import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useMixpanel from '@hooks/useMixpanel'
import useSelector from '@hooks/useSelector'

import * as comparableActions from '@redux/modules/comparable'

import defaultFormState, { requiredFields } from '@models/comparable'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ComparableModel, ComparableRequestOptions, CustomFieldModel } from '@models/types'

const watchEntityKeys = ['images']

type CreateComparableParams = {
  dispatch: AppDispatch,
  payload: {
    comparableParams: Partial<ComparableModel>,
    customFields: CustomFieldModel[],
  },
  requestOptions?: ComparableRequestOptions,
}

const createComparable = (params: CreateComparableParams) => {
  const { dispatch, payload, requestOptions } = params
  const { comparableParams, customFields } = payload

  const { createComparable: createFn } = comparableActions

  const { data } = comparableParams
  const newComparable = {
    ...comparableParams,
    data: JSON.stringify(data),
  }

  return dispatch(createFn(newComparable, customFields, requestOptions))
}

type UpdateComparableParams = {
  comparable: ComparableModel,
  dispatch: AppDispatch,
  payload: {
    comparableParams: Partial<ComparableModel>,
    customFields: CustomFieldModel[],
  },
  requestOptions?: ComparableRequestOptions,
}

const updateComparable = (params: UpdateComparableParams) => {
  const {
    comparable, dispatch, payload, requestOptions,
  } = params
  const { comparableParams, customFields } = payload

  const { updateComparable: updateFn } = comparableActions
  const { data } = comparableParams

  const updatedParams = {
    id: comparable.id,
    ...comparableParams,
  }

  if (data){
    const comparableData = comparable.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(comparableData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type DeleteComparableParams = {
  comparable: DeleteParams<ComparableModel>,
  dispatch: AppDispatch,
}

const deleteComparable = (params: DeleteComparableParams) => {
  const { dispatch, comparable } = params
  const { deleteComparable: deleteFn } = comparableActions

  return dispatch(deleteFn(comparable))
}

type CloneComparableParams = {
  dispatch: AppDispatch,
  payload: {
    sourceComparableId: number,
    targetProjectId: number,
  },
  requestOptions?: ComparableRequestOptions,
}

const cloneComparable = (params: CloneComparableParams) => {
  const { payload, dispatch, requestOptions } = params
  const { sourceComparableId, targetProjectId } = payload

  const { cloneComparable: cloneFn } = comparableActions
  return dispatch(cloneFn(sourceComparableId, targetProjectId, requestOptions))
}

type ComparableDataStoreItem = {
  key: string,
  value: string,
}

const buildComparableDataStoreValues = (comparableDataStoreItems: ComparableDataStoreItem[]) => {
  const dataStoreValues = comparableDataStoreItems.reduce((acc, dataStoreItem) => {
    const { key, value } = dataStoreItem

    acc[key] = value || ''

    return acc
  }, {})

  return dataStoreValues
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useComparableForm(
  comparable: Partial<ComparableModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const comparableForm = useForm(
    defaultFormState,
    { entity: comparable, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [comparable.id, comparable.cache_key],
  )

  return {
    ...comparableForm,
  }
}

function useComparable(initEntity: Partial<ComparableModel> = {}) {
  const { entity: comparable }: { entity: ComparableModel} = useLatestEntity(initEntity, 'comparables')

  const { address_id, data_store_items } = comparable

  const {
    updatedEntities: {
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)
  const { showFindComparablesModal } = callbacks || {}

  const {
    entities,
    entities: { addresses, dataStoreItems },
  } = useSelector(reduxState => reduxState)

  const address = addresses[address_id] || {}
  const { full_address } = address

  const comparableDataStoreItems = Object.values(dataStoreItems)
    .filter(dataStoreItem => data_store_items?.includes(dataStoreItem.id))

  const comparableFeatures = buildComparableDataStoreValues(comparableDataStoreItems)

  const imageFilters = [
    { key: 'subject_id', value: comparable.id },
    { key: 'subject_type', value: 'ProjectComparable' },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, comparable.id])

  const defaultImage = filteredImages[0] || {}

  const { creating, loading, updating } = useSelector(reduxState => reduxState.comparables)

  type HandleComparableSourceSelectorParams = {
    selectedAction?: 'browse' | 'clone' | 'search',
    setModalState?: (state: { action: string }) => void,
  }

  const handleComparableSourceSelector = (params: HandleComparableSourceSelectorParams = {}) => {
    const { selectedAction, setModalState } = params
    if (selectedAction){
      if (selectedAction === 'search'){
        dispatchMixpanelEvent('Search Exact Match', { project_id: comparable.project_id })
      } else if (selectedAction === 'clone'){
        dispatchMixpanelEvent('Use Existing Comparables', { project_id: comparable.project_id })
      }
      setModalState?.({ action: selectedAction })
    } else {
      launchModal({
        callbacks,
        modalKey: 'CreateOrEditComparableModal',
        payload: {
          comparable,
        },
      })
      dispatchMixpanelEvent('Add Manual Comparable', { project_id: comparable.project_id })
    }
  }

  return {
    address,
    callbacks: {
      createComparable: (
        payload: {
          comparableParams: Partial<ComparableModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: ComparableRequestOptions,
      ) => (
        createComparable({ dispatch, payload, requestOptions: entityOptions })
      ),
      createOrEditComparable: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditComparableModal',
        payload: {
          comparable,
          ...customPayload,
        },
      }),
      cloneComparable: (
        payload: {
          sourceComparableId: number,
          targetProjectId: number,
        },
        entityOptions?: ComparableRequestOptions,
      ) => cloneComparable({ dispatch, payload, requestOptions: entityOptions }),
      deleteComparable: () => deleteComparable({ dispatch, comparable }),
      handleComparableSourceSelector: (
        action?: 'browse' | 'clone' | 'search',
        setModalState?: (state: { action: string }) => void,
      ) => handleComparableSourceSelector({ selectedAction: action, setModalState }),
      updateComparable: (
        payload: {
          comparableParams: Partial<ComparableModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: ComparableRequestOptions,
      ) => (
        updateComparable({
          comparable, dispatch, payload, requestOptions: entityOptions,
        })
      ),
      showFindComparablesModal,
    },
    comparable,
    comparableDataStoreItems,
    comparableFeatures,
    creating,
    defaultImage,
    filteredImages,
    fullAddress: full_address,
    loading,
    updating,
  }
}

export default useComparable
