import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTasks } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Link, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'

import ComparableResult from '../ComparableResult'
import Filters from '../Filters'

import localizedStrings from './localizedStrings'
import BrowseComparablesFilters from './BrowseComparablesFilters'

const renderOptionText = (browseComparableType, strings) => {
  const comparableTypes = {
    market_all: strings.marketAll || 'All Market',
    market_current: strings.marketCurrent || 'Current Market',
    market_rental: strings.marketRental || 'Current Rental',
    market_sold: strings.marketSold || 'Sold Market',
    organization_all: strings.organizationAll || 'All Organization',
    organization_current: strings.organizationCurrent || 'Current Organization',
    organization_sold: strings.organizationSold || 'Sold Organization',
  }

  return comparableTypes[browseComparableType] || ''
}

const defaultState = {
  viewMode: 'tile',
}

const BrowseResults = (props) => {
  const { filteredComparables, useFindComparablesPayload } = props
  const {
    browseAddressFilter,
    browseComparableType,
    browseComplete,
    callbacks: {
      createComparableFromProject,
      importComparable,
      setFilterComparablesBySuburbKey,
      setModalState,
    },
    filterComparablesBySuburbKey,
    filteredBrowseResults,
    resultsCount,
    searching,
    selectedIntegrationPlatformKey,
  } = useFindComparablesPayload

  const [state, setState] = useSetState(defaultState)
  const { viewMode } = state

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink={0}>
      <Filters useFindComparablesPayload={useFindComparablesPayload} />

      <LoadingModule boxProps={{ marginTop: 'large', marginBottom: 0 }} loading={searching} />

      {browseComplete && (
        <>
          <Box fontSize="xsmall" color="bodyFontLightColor" marginTop="large">
            {resultsCount}
            <Text as="span">
              &nbsp;{renderOptionText(browseComparableType, strings)} {strings.comparable || 'Comparable'}&nbsp;
            </Text>
            {strings.propertiesReturned || 'properties returned'}
          </Box>

          <FormField label={strings.addressFilter || 'Address Filter'} marginTop="large">
            <input
              onChange={e => setModalState({ browseAddressFilter: e.target.value })}
              style={{ marginBottom: 0 }}
              type="text"
              value={browseAddressFilter}
            />
          </FormField>

          {selectedIntegrationPlatformKey !== 'pricefinder' && (
            <Box marginTop="large" justifyContent="flex-end">
              <Link
                disabledUnderline
                onClick={() => {
                  setState({ viewMode: viewMode === 'list' ? 'tile' : 'list' })
                }}
                textProps={{ fontSize: 'small' }}
              >
                <FontAwesomeIcon icon={viewMode === 'list' ? faImage : faTasks} /> &nbsp;
                {viewMode === 'list' ? strings?.tileViewMode || 'Tile View' : strings?.listViewMode || 'List View'}
              </Link>
            </Box>
          )}

          <BrowseComparablesFilters
            filterComparablesBySuburbKey={filterComparablesBySuburbKey}
            setFilterComparablesBySuburbKey={setFilterComparablesBySuburbKey}
          />

          {filteredBrowseResults.length === 0 ? (
            <Text color="grey" fontSize="small" marginTop="large" textAlign="center" lineHeight="1.5">
              {filterComparablesBySuburbKey === 'this_suburb' ? (
                strings.noSuburbResults
                  || `None of the returned comparable properties are in this suburb.
                      Check the "Adjoining Suburbs" tab or adjust the search filters to see other results.`
              ) : (
                strings.noNearbyResults
                  || `None of the returned comparable properties are in adjoining suburbs.
                      Check the "This Suburb" tab or adjust the search filters to see other results.`
              )}
            </Text>
          ) : (
            filteredBrowseResults.map((result, index) => (
              <ComparableResult
                createComparableFromProject={createComparableFromProject}
                externalComparable={result}
                filteredComparables={filteredComparables}
                importComparable={importComparable}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                viewMode={viewMode}
              />
            ))
          )}
        </>
      )}
    </Box>
  )
}

BrowseResults.propTypes = {
  filteredComparables: PropTypes.array.isRequired,
  useFindComparablesPayload: PropTypes.object.isRequired,
}

export default BrowseResults
