import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import type { AddressModel } from '@models/address'
import type { ProjectModel } from '@models/project'

import { useRelations as useProjectRelations } from '@hooks/useProject'
import useAuthorizeIntegration from '@hooks/useAuthorizeIntegration'
import useCurrentUser from '@hooks/useCurrentUser'
import useIntegrations from '@hooks/useIntegrations'

import * as comparableActions from '@redux/modules/comparable'
import * as projectActions from '@redux/modules/project'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import convertArea, { AreaUnit } from '@functions/areaFilterConverter'

import type { AppDispatch } from '@redux/store'

import buildFilters from '../utils/buildFilters'
import defaultFilters from '../utils/defaultFilters'

const mappedFilterKeys = {
  date: 'date.sold',
  distance: 'distance',
  price: 'price.price',
}

type IntegrationPlatformType = {
  data: {
    import_comparables_options: {
      browse_filters: string[],
    },
  },
}

type ComparableParams = {
  id: number,
  distance?: number,
}

type AreaFilterChangeParams = {
  filterId: string,
  key: string,
  value: any,
}

type Comparable = {
  address: AddressModel,
  agency: { title: string},
  agent: { title: string }[],
  data_provider: { key: string, title: string },
  dates: {
    sold: { date_time: string },
  },
  distance: number,
  extra_fields: {
    coords: { latitude: number, longitude: number },
  },
  features: {},
  id: number,
  image: {
    thumb: string,
  },
  land: { area: number, unit: string },
  price: { display: true, price_guide: number, price: number },
  property_type: string,
  raw_data: Record<string, any>,
  rental: boolean,
  service_provider: string,
  sold: boolean,
}

type BrowseResult = {
  address: AddressModel,
  data_provider: { title: string },
  features: Record<string, any>,
  id: number,
  land: Record<string, any>,
  price: Record<string, any>,
  raw_data: Record<string, any>,
}

type SelectedValues = {
  title: string,
  value: string,
}

type Filter = {
  areaUnit?: SelectedValues,
  id: string,
  selectedValues?: SelectedValues[],
  title?: string,
  value?: string,
  valueMax?: string,
  width?: number,
}

type CombinedSearchResult = {
  address: string,
  id: number,
  image: {
    thumb: string | null,
  },
  postcode: string,
  service_provider: string,
  state_name: string,
}

type BrowseComparableType = 'market_all' | 'market_current' | 'market_rental' | 'market_sold';

type State = {
  action: string,
  blankFilters: Filter[],
  browseAddressFilter: string,
  browseComparableType: BrowseComparableType,
  browseComplete: boolean,
  browseResults: Comparable[],
  cloneFromProjectId: number | null,
  combinedSearchResult: CombinedSearchResult[],
  errors: Record<string, any>,
  filterComparablesBySuburbKey: string,
  filtersArray: Filter[],
  marketResults: Record<string, any>,
  organizationResults: Record<string, any>,
  propertyMatchId: string,
  propertyMatchSuggestions: Record<string, any>,
  resultsCount: number | null,
  searchComplete: boolean,
  searchString: string,
  searchedString: string,
  searching: boolean,
  selectedIntegrationPlatformKey: string,
  showFilters: boolean,
}

const defaultState: State = {
  action: 'default',
  blankFilters: [],
  browseAddressFilter: '',
  browseComparableType: 'market_all',
  browseComplete: false,
  browseResults: [],
  cloneFromProjectId: null,
  combinedSearchResult: [],
  errors: [],
  filterComparablesBySuburbKey: 'this_suburb',
  filtersArray: [],
  marketResults: [],
  organizationResults: [],
  propertyMatchId: '',
  propertyMatchSuggestions: [],
  resultsCount: null,
  searchComplete: false,
  searchString: '',
  searchedString: '',
  searching: false,
  selectedIntegrationPlatformKey: '',
  showFilters: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

type GetFiltersForIntegrationPlatformParams = {
  integrationPlatform: IntegrationPlatformType,
}

export const getFiltersforIntegrationPlatform = (params: GetFiltersForIntegrationPlatformParams) => {
  const { integrationPlatform } = params
  const platformFilters = digObject(integrationPlatform, 'data.import_comparables_options.browse_filters', [])
  const mappedFilters = platformFilters.map((filter: keyof typeof defaultFilters) => defaultFilters[filter])

  return mappedFilters
}

const combinedSearch = (
  externalPlatformKey: string,
  searchString: string,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const { combinedSearch: combinedSearchFn } = comparableActions
  // Reset state
  setState({
    combinedSearchResult: [],
    errors: [],
    searchComplete: false,
    searching: true,
  })

  type CombinedSearchResponse = {
    data: CombinedSearchResult[],
    errors?: string[],
    success: boolean,
  }

  return dispatch(combinedSearchFn(externalPlatformKey, { string: searchString }))
    .then((response: CombinedSearchResponse) => {
      const { data, errors, success } = response
      setState({
        combinedSearchResult: success ? data : [],
        errors: !success ? errors : [],
        resultsCount: success ? data.length : 0,
        searchComplete: true,
        searchedString: searchString,
        searching: false,
      })
      return response
    })
}

const mapFiltersArrayToObject = (filtersArray: Filter[]) => {
  const filters = filtersArray.reduce((acc: { [key: string]: Filter }, filter: Filter) => {
    acc[filter.id] = filter
    return acc
  }, {})

  return filters
}

const fetchFromExternalPlatform = (
  state: State,
  externalId: number,
  organizationId: number,
  projectAddress: AddressModel,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const { fetchFromExternalPlatform: fetchFromExternalPlatformFn } = comparableActions
  const { browseComparableType, filtersArray, selectedIntegrationPlatformKey } = state
  const filters = mapFiltersArrayToObject(filtersArray)
  const browseFilters = buildFilters(filters)
  setState({ searching: true })
  const comparableTypes = {
    market_all: 'all',
    market_current: 'listed',
    market_rental: 'rental',
    market_sold: 'sold',
  }
  const comparableType = comparableTypes[browseComparableType]
  const payload = {
    externalId,
    comparableType,
    organizationId,
    projectAddress,
  }

  type FetchFromExternalPlatformResponse = {
    data: Comparable[],
    success: boolean,
  }

  return dispatch(fetchFromExternalPlatformFn(selectedIntegrationPlatformKey, payload, browseFilters)).then(
    (response: FetchFromExternalPlatformResponse) => {
      setState({ searching: false })
      const { success, data } = response
      if (success){
        setState({
          browseComplete: true,
          browseResults: data,
          resultsCount: data.length,
        })
      }
      return response
    },
  )
}

const fetchProjects = (
  state: State,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const { searchProjects } = comparableActions
  const { browseComparableType, filtersArray } = state
  const comparableTypes = {
    organization_all: 'all',
    organization_current: 'listed',
    organization_sold: 'sold',
  }
  const comparableType = comparableTypes[browseComparableType]
  const filters = mapFiltersArrayToObject(filtersArray)
  const browseFilters = {
    ...buildFilters(filters),
    comparable_type: comparableType,
  }
  setState({ searching: true })
  return dispatch(searchProjects(browseFilters)).then((response: Record<string, any>) => {
    setState({ searching: false })
    const { success, data } = response
    if (success){
      setState({
        browseComplete: true,
        browseResults: data.entities,
        resultsCount: data.entities.length,
      })
    }
    return response
  })
}

const performSubmit = (
  externalId: number,
  organizationId: number,
  projectAddress: AddressModel,
  state: State,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const {
    action, browseComparableType, searchString, selectedIntegrationPlatformKey,
  } = state
  if (action === 'search'){
    return combinedSearch(selectedIntegrationPlatformKey, searchString, dispatch, setState)
  }
  if (action === 'browse'){
    if (browseComparableType.includes('market')){
      return fetchFromExternalPlatform(state, externalId, organizationId, projectAddress, dispatch, setState)
    }
    return fetchProjects(state, dispatch, setState)
  }
  return null
}

const importComparable = (
  externalPlatformKey: string,
  comparableParams: ComparableParams,
  payload: any,
  dispatch: AppDispatch,
) => {
  const { importComparable: importComparableFn } = comparableActions
  const { id, distance } = comparableParams
  const updatedPayload = {
    ...payload,
    externalId: id,
    distance,
  }
  const requestOptions = {
    ...defaultRequestOptions.comparable,
    ...defaultRequestOptions.image,
  }

  return dispatch(importComparableFn(externalPlatformKey, updatedPayload, requestOptions))
}

const createComparableFromProject = (
  comparableParams: ComparableParams,
  comparableOwnerId: number,
  dispatch: AppDispatch,
) => {
  const { createComparableFromProject: createComparableFromProjectFn } = comparableActions
  const { id } = comparableParams
  const requestOptions = {
    ...defaultRequestOptions.comparable,
    ...defaultRequestOptions.image,
  }

  return dispatch(createComparableFromProjectFn(id, comparableOwnerId, requestOptions))
}

const updateProjectExternalId = (
  externalPlatformKey: string,
  project: ProjectModel,
  externalId: number,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const { updateProject: updateProjectFn } = projectActions

  setState({ searching: true })

  const { data } = project
  const { external_ids } = data

  // Need to merge nested levels of data to prevent overwrite
  const updatedExternalIds = {
    ...external_ids,
    [externalPlatformKey]: externalId,
  }

  const updatedData = {
    ...data,
    external_ids: updatedExternalIds,
  }

  const updatedProject = {
    id: project.id,
    data: JSON.stringify(updatedData),
  }

  return dispatch(updateProjectFn(updatedProject)).then((response) => {
    setState({ searching: false })
    return response
  })
}

const fetchPropertyMatchSuggestions = (
  externalPlatformKey: string,
  address: string,
  organizationId: number,
  dispatch: AppDispatch,
  setState: SetState,
) => {
  const { searchInExternalPlatform: searchInExternalPlatformFn } = comparableActions
  if (externalPlatformKey){
    setState({
      errors: [],
      propertyMatchSuggestions: [],
      searching: true,
    })

    type FetchPropertyMatchSuggestionsResponse = {
      data: Comparable[],
      errors?: string[],
      success: boolean,
    }
    dispatch(
      searchInExternalPlatformFn(externalPlatformKey, { string: address, organization_id: organizationId }),
    ).then((result: FetchPropertyMatchSuggestionsResponse) => {
      const { data, errors, success } = result
      setState({
        errors: !success ? errors : [],
        propertyMatchSuggestions: success ? data : [],
        searching: false,
      })
    })
  }
}

type ChangeFilterParams = {
  key: string,
  name: string,
  value: any,
  state: State,
  setState: SetState,
}

const changeFilter = (params: ChangeFilterParams) => {
  const {
    key, name, value, state, setState,
  } = params
  const { filtersArray } = state

  const newFilters = [...filtersArray]
  newFilters.forEach((filter, index) => {
    if (filter.id === key) newFilters[index][name] = value
  })

  setState({
    filtersArray: newFilters,
    browseResults: [],
    resultsCount: 0,
  })
}

type SortFilterBrowseResultsParams = {
  filtersArray: Filter[],
  filteredBrowseResults: BrowseResult[],
}

const sortFilteredBrowseResults = (params: SortFilterBrowseResultsParams) => {
  const { filtersArray, filteredBrowseResults } = params
  if (!filtersArray.length) return filteredBrowseResults

  const filter = filtersArray.find(f => f.id === 'sort')
  const filterValue = filter?.value || ''
  const sortDirection = filterValue.startsWith('-') ? 'desc' : 'asc'
  const selectedFilterKey = filterValue.replace('-', '')

  const key = mappedFilterKeys[selectedFilterKey] || 'date.sold'

  return sortArrayBy(filteredBrowseResults, sortDirection, browseResult => digObject(browseResult, key, ''))
}

const useFindComparables = (projectId: number) => {
  const [state, setState] = useSetState(defaultState)
  const {
    action,
    blankFilters,
    browseAddressFilter,
    browseComparableType,
    browseComplete,
    browseResults,
    cloneFromProjectId,
    combinedSearchResult,
    errors,
    filterComparablesBySuburbKey,
    filtersArray,
    propertyMatchId,
    propertyMatchSuggestions,
    resultsCount,
    searchComplete,
    searchString,
    searchedString,
    searching,
    selectedIntegrationPlatformKey,
    showFilters,
  } = state

  const { countries, projects } = useSelector(reduxState => reduxState.entities)
  const project = projects[projectId] || {}
  const organizationId = project.organization_id
  const projectRelations = useProjectRelations({ id: projectId })
  const { address: projectAddress } = projectRelations as { address: AddressModel }

  const currentUserPayload = useCurrentUser()
  const { currentUser } = currentUserPayload

  const { filteredPlatforms } = useIntegrations({
    featureKeys: ['import_comparables'],
    filters: {
      subject_id: currentUser.id,
      subject_type: 'User',
    },
  })

  const selectedIntegrationPlatform = filteredPlatforms.find(
    platform => platform.key === selectedIntegrationPlatformKey,
  ) || {}

  const dispatch = useThunkDispatch()

  // Split combinedSearchResult into organizationResults and marketResults
  const splitResult = combinedSearchResult.reduce(
    (acc, comparable) => {
      if (comparable && comparable.service_provider === 'engage'){
        acc.organizationResults.push(comparable)
      } else {
        acc.marketResults.push(comparable)
      }

      return acc
    },
    { organizationResults: [], marketResults: [] },
  )

  const organizationResults = digObject(splitResult, 'organizationResults', [])
  const marketResults = digObject(splitResult, 'marketResults', [])

  useEffect(() => {
    if (searchComplete) setState({ searchComplete: false })
  }, [searchString])

  useEffect(() => {
    setState({
      browseAddressFilter: '',
      browseComplete: false,
      browseResults: [],
      marketResults: [],
      organizationResults: [],
      searchComplete: false,
      searchString: '',
      searchedString: '',
    })
  }, [browseComparableType, selectedIntegrationPlatformKey])

  // Authorize Integration Payload to handle REINZ External Authorization
  const useAuthorizeIntegrationPayload = useAuthorizeIntegration({ filteredPlatforms, selectedIntegrationPlatformKey })
  const {
    integrationExpired,
    oauthProviderKey,
  } = useAuthorizeIntegrationPayload || {}

  // Set the external ID of the project if there isn't one
  const externalId = digObject(project, `data.external_ids.${selectedIntegrationPlatformKey}`)
  useEffect(() => {
    if (!externalId && action === 'browse' && organizationId && projectAddress){
      if (oauthProviderKey && integrationExpired){
        return
      }

      fetchPropertyMatchSuggestions(
        selectedIntegrationPlatformKey,
        projectAddress.full_address,
        organizationId,
        dispatch,
        setState,
      )
    }
  }, [action, externalId, selectedIntegrationPlatformKey])

  // Autoselect integration platform if there is only 1
  useEffect(() => {
    if (filteredPlatforms.length === 1){
      setState({ selectedIntegrationPlatformKey: filteredPlatforms[0].key })
    }
  }, [])

  // Reset button when filters change
  useEffect(() => {
    if (browseComplete) setState({ browseComplete: false })
  }, [JSON.stringify(filtersArray)])

  const country = countries[projectAddress?.country_id] || {}

  // Set pricefinder as the default integration platform for Australia
  useEffect(() => {
    if (country?.code === 'AU') setState({ selectedIntegrationPlatformKey: 'pricefinder' })
  }, [country])

  // Reset browseComplete and searchComplete when action changes
  useEffect(() => {
    setState({ browseComplete: false, searchComplete: false })
  }, [action])

  const filters = getFiltersforIntegrationPlatform({ integrationPlatform: selectedIntegrationPlatform })

  // Save a copy of blank filters so we can reset
  useEffect(() => {
    setState({ blankFilters: cloneDeep(filters) })
  }, [selectedIntegrationPlatformKey])

  useEffect(() => {
    setState({ filtersArray: filters })
  }, [selectedIntegrationPlatformKey, filters.length])

  // Automatically set filters for latitude and longitude
  useEffect(() => {
    if (filtersArray.length){
      changeFilter({
        key: 'latitude', name: 'value', value: projectAddress.latitude, state, setState,
      })
      changeFilter({
        key: 'longitude', name: 'value', value: projectAddress.longitude, state, setState,
      })
    }
  }, [projectAddress.latitude, projectAddress.longitude, filtersArray.length])

  const activeFiltersCount = Object.values(filters).filter(
    f => (f.value && f.id !== 'sort' && f.id !== 'radius') || (f.id === 'radius' && f.value !== '2'),
  ).length

  const projectSuburb = projectAddress?.region_name || ''

  const filterBySuburb = (comparables, projectSuburbArg, matchSuburb) => comparables.filter((comparable) => {
    const suburb = comparable.address?.suburb_name?.toLowerCase() || ''
    if (!projectSuburbArg) return !matchSuburb
    const suburbMatch = suburb === projectSuburbArg.toLowerCase()
    return matchSuburb ? suburbMatch : !suburbMatch
  })

  const sortedFilteredBrowseResults = () => {
    let results = [...browseResults]

    const shouldReturnSuburbResults = filterComparablesBySuburbKey === 'this_suburb'
    results = filterBySuburb(results, projectSuburb, shouldReturnSuburbResults)

    if (browseAddressFilter){
      results = results.filter((result) => {
        const { address, suburb_name } = result.address
        const stringToSearch = `${address}, ${suburb_name}`.toLowerCase()
        return stringToSearch.includes(browseAddressFilter.toLowerCase())
      })
    }

    results = sortFilteredBrowseResults({ filtersArray, filteredBrowseResults: results })

    return results
  }

  const payload = {
    organizationId,
    projectId,
  }

  const hasPropertyMatchSuggestions = !!propertyMatchSuggestions.length

  const [areaFilterDisplayValue, setAreaFilterDisplayValue] = useState({
    value: '',
    valueMax: '',
  })

  const priceFinderAreaOptions = [
    { title: 'Square Meter', value: 'm2' },
    { title: 'Acre', value: 'ac' },
    { title: 'Hectare', value: 'ha' },
  ]

  const pricefinderAreaFilter = selectedIntegrationPlatformKey === 'pricefinder'
    ? filtersArray.find(filter => filter.id === 'area')
    : null

  const filtersWithoutArea = selectedIntegrationPlatformKey === 'pricefinder'
    ? filtersArray.filter(filter => filter.id !== 'area')
    : filtersArray

  const areaFilter = filtersArray.find(filter => filter.id === 'area')
  const selectedAreaUnitValue = areaFilter?.areaUnit?.value || ''

  const handleAreaFilterChange = ({ filterId, key, value }: AreaFilterChangeParams) => {
    if (pricefinderAreaFilter){
      const valueInSqM = convertArea({ value, fromUnit: selectedAreaUnitValue as AreaUnit, toUnit: 'm2' })
      setAreaFilterDisplayValue(prev => ({
        ...prev,
        [key]: value,
      }))
      changeFilter({
        key: filterId, name: key, value: valueInSqM, state, setState,
      })
    } else {
      changeFilter({
        key: filterId, name: key, value, state, setState,
      })
    }
  }

  const handleAreaUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const oldUnit = selectedAreaUnitValue
    const newUnit = e.target.value
    const selectedOption = priceFinderAreaOptions.find(
      option => option.value === newUnit,
    )

    const { value: minValue, valueMax: maxValue } = areaFilterDisplayValue

    let newMinValue = ''
    let newMaxValue = ''

    if (minValue){
      const valueInSqM = convertArea({ value: minValue, fromUnit: oldUnit as AreaUnit, toUnit: 'm2' })
      newMinValue = convertArea({ value: valueInSqM, fromUnit: 'm2', toUnit: newUnit as AreaUnit })
    }

    if (maxValue){
      const valueInSqM = convertArea({ value: maxValue, fromUnit: oldUnit as AreaUnit, toUnit: 'm2' })
      newMaxValue = convertArea({ value: valueInSqM, fromUnit: 'm2', toUnit: newUnit as AreaUnit })
    }

    setAreaFilterDisplayValue({
      value: newMinValue,
      valueMax: newMaxValue,
    })

    if (pricefinderAreaFilter){
      if (newMinValue !== ''){
        const minValueInSqM = convertArea({ value: newMinValue, fromUnit: newUnit as AreaUnit, toUnit: 'm2' })
        changeFilter({
          key: 'area', name: 'value', value: minValueInSqM, state, setState,
        })
      }
      if (newMaxValue !== ''){
        const maxValueInSqM = convertArea({ value: newMaxValue, fromUnit: newUnit as AreaUnit, toUnit: 'm2' })
        changeFilter({
          key: 'area', name: 'valueMax', value: maxValueInSqM, state, setState,
        })
      }
    }

    changeFilter(
      {
        key: 'area',
        name: 'areaUnit',
        value: {
          title: selectedOption?.title || '',
          value: newUnit,
        },
        state,
        setState,
      },
    )
  }

  return {
    action,
    activeFiltersCount,
    areaFilter,
    areaFilterDisplayValue,
    selectedAreaUnitValue,
    browseAddressFilter,
    browseComparableType,
    browseComplete,
    callbacks: {
      changeFilter: (key: string, name: string, value: any) => changeFilter({
        key, name, value, state, setState,
      }),
      combinedSearch: (externalPlatformKey: string, string: string) => (
        combinedSearch(externalPlatformKey, string, dispatch, setState)
      ),
      createComparableFromProject: (params: ComparableParams) => (
        createComparableFromProject(params, projectId, dispatch)
      ),
      fetchProjects: () => fetchProjects(state, dispatch, setState),
      handleAreaFilterChange: ({ filterId, key, value }: AreaFilterChangeParams) => (
        handleAreaFilterChange({ filterId, key, value })
      ),
      handleAreaUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => handleAreaUnitChange(e),
      importComparable: (params: ComparableParams) => (
        importComparable(selectedIntegrationPlatformKey, params, payload, dispatch)
      ),
      resetFilters: () => setState({ filtersArray: cloneDeep(blankFilters) }),
      setFilterComparablesBySuburbKey: value => setState({ filterComparablesBySuburbKey: value }),
      setModalState: (values: State) => setState(values),
      submitAction: () => performSubmit(externalId, organizationId, projectAddress, state, dispatch, setState),
      updateProjectExternalId: (projectExternalId: number) => (
        updateProjectExternalId(selectedIntegrationPlatformKey, project, projectExternalId, dispatch, setState)
      ),
    },
    cloneFromProjectId,
    combinedSearchResult,
    errors,
    externalId,
    filterComparablesBySuburbKey,
    filteredBrowseResults: sortedFilteredBrowseResults(),
    filteredPlatforms,
    filtersArray,
    filtersWithoutArea,
    hasMarketResults: !!marketResults?.length,
    hasOrganizationResults: !!organizationResults?.length,
    hasPropertyMatchSuggestions,
    isMatchedWithDataProvider: !!externalId,
    marketResults,
    organizationResults,
    priceFinderAreaOptions,
    pricefinderAreaFilter,
    projectId,
    propertyMatchId,
    propertyMatchSuggestions,
    resultsCount,
    searchComplete,
    searchString,
    searchedString,
    searching,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    showFilters,
    useAuthorizeIntegrationPayload,
  }
}

export type UseFindComparablesPayload = ReturnType<typeof useFindComparables>

export default useFindComparables
